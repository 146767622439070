<template>
  <a-card title="Bulk Publikasi Produk Channel" :bordered="false">
    <p class="mt-4">
      Cara mudah untuk publish banyak produk sekaligus.
    </p>
  </a-card>
</template>
  
  <script>
  import { downloadFile, uploadFile } from '@/api/product'
  
  export default {
    name: 'ImportProductPage',
    data() {
      return {
      }
    },
    methods: {
    },
  }
  </script>
  
  <style lang="scss">
  </style>